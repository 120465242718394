import * as React from 'react';

import clsx from 'clsx';
import { X } from 'phosphor-react';

import { Button, useUIDispatch } from '@/components/ui';

export interface StructuredModalProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title' | 'width'> {
  title?: string | null;
  header?: React.ReactNode;
  children: React.ReactNode;
  footer: React.ReactNode;
  className?: string;
}

export const StructuredModal = React.memo<StructuredModalProps>(({ title, children, header, footer, className }) => {
  const uiDispatch = useUIDispatch();

  return (
    <div className='overflow-y-scroll overscroll-contain max-h-[75vh] flex flex-col' id='admin-modal-scroll'>
      <header className='sticky top-0 z-10 flex items-center justify-between py-7 px-8 border-b border-b-khaki-3 bg-khaki-1 gap-x-12'>
        <div className='font-josefin font-bold text-2xl tracking-tighter'>{title}</div>
        <div className='flex items-center gap-4'>
          {header}
          <Button
            aria-label='Close panel'
            className='p-2 rounded-full bg-khaki-2 border-0'
            onClick={() => uiDispatch({ type: 'MODAL_CLOSE' })}
            variant='ghost'
          >
            <X className='w-5 h-5' />
          </Button>
        </div>
      </header>
      <div className={clsx('grow p-8', className)}>{children}</div>
      <footer className='sticky bottom-0 z-10 flex items-center justify-between py-5 px-8 bg-khaki-2-3'>
        {footer}
      </footer>
    </div>
  );
});

StructuredModal.displayName = 'StructuredModal';
