import * as React from 'react';

import clsx from 'clsx';
import NextImage from 'next/image';
import { Play, LockSimple } from 'phosphor-react';

import { Link } from '@/components/common';
import { Button, Divider, HorizontalList, useUIDispatch } from '@/components/ui';
import { useAnalyticsDispatch } from '@/context/analytics';
import { imgixLoader } from '@/utils/images';
import { findPlayback, formatDuration } from '@/utils/mux';
import { capitalize } from '@/utils/string';
import { trpc } from '@/utils/trpc';

import { RouterOutput } from '../../../trpc/router';

export interface WorkoutMegaCardProps {
  className?: string;
  dbUser: RouterOutput['users']['byId']['data'];
  kicker?: string;
  workout: RouterOutput['users']['workout']['featuredWorkout'];
}

export const WorkoutMegaCard = React.memo<WorkoutMegaCardProps>(({ className, dbUser, kicker, workout }) => {
  const uiDispatch = useUIDispatch();
  const analyticsDispatch = useAnalyticsDispatch();

  const { data: playbacks } = trpc.videos.playbacks.useQuery(
    { workoutId: workout.id, videoId: workout.videoId ?? '' },
    {
      enabled: !!dbUser.id,
      select: (data) => data?.playbacks ?? [],
    },
  );

  const playback = React.useMemo(() => findPlayback(playbacks), [playbacks]);

  const handlePlayNow = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      if (!playback) return;
      uiDispatch({ type: 'WORKOUT_PLAYER_OPEN', playback, workout });
    },
    [playback, uiDispatch, workout],
  );

  return (
    <div className={clsx(className, 'max-w-7xl md:px-12')}>
      <div className='grid p-6 md:p-0 bg-khaki-1 py-6 gap-6 overflow-hidden md:grid-cols-[1fr_461px] md:gap-0 md:rounded-2xl'>
        {workout.image && (
          <Link
            className='flex relative'
            href={`/workouts/${workout.slug}`}
            onClick={() => {
              analyticsDispatch({
                type: 'WORKOUT_VIEWED',
                payload: {
                  dbUser,
                  workout,
                  location: "Dashboard: Today's Workout",
                },
              });
            }}
          >
            {workout.isLocked && (
              <div className='rounded-bl-2xl absolute right-0 h-10 w-10 bg-khaki-1 justify-center items-center flex'>
                <LockSimple className='w-4 h-4' weight='fill' />
              </div>
            )}
            <NextImage
              alt={workout.title}
              className='h-100 rounded-2xl object-cover md:rounded-none'
              height={408}
              loader={imgixLoader}
              priority={true}
              src={`/${workout.image.fileName}`}
              width={739}
            />
          </Link>
        )}
        <div className='flex flex-col items-start gap-6 md:justify-center md:px-12 md:pb-12'>
          <header className='flex flex-col gap-2 md:col-start-2 md:row-start-1 md:pt-12'>
            <div className='font-josefin text-xs font-bold tracking-widest uppercase text-transparent bg-clip-text bg-gradient-yellow-to-purple'>
              {kicker}
            </div>
            <h1 className='font-josefin text-2xl tracking-tighter leading-tight font-bold m-0'>{workout.title}</h1>
            <HorizontalList
              items={[
                workout.video?.duration ? formatDuration(workout.video.duration) : '0s',
                capitalize(workout.targetArea?.replace(/_/g, ' ').toLowerCase()),
                capitalize(workout.intensity?.toLowerCase()) + ' Intensity',
              ]}
            />
          </header>
          {workout.why && (
            <div className='flex gap-8'>
              <Divider className='h-full text-khaki-3' direction='vertical' variant='stubby' />
              <div>
                <h3 className='mb-1 font-bold tracking-tighter font-josefin'>Why We Recommend It</h3>
                <p className='text-sm leading-normal'>{workout.why}</p>
              </div>
            </div>
          )}
          {playback && (
            <Button data-testid='dashboard-play-now-button' onClick={handlePlayNow} variant='primary'>
              {workout.isLocked ? (
                <LockSimple className='w-4 h-4' weight='fill' />
              ) : (
                <Play className='w-4 h-4' weight='fill' />
              )}
              Play Now
            </Button>
          )}
        </div>
      </div>
    </div>
  );
});

WorkoutMegaCard.displayName = 'WorkoutMegaCard';
