import * as React from 'react';

import {
  Accordion as ReachAccordion,
  AccordionProps,
  AccordionButton as ReachAccordionButton,
  AccordionButtonProps as AccordionButtonProps,
} from '@reach/accordion';
import '@reach/accordion/styles.css';
import { CaretDown } from 'phosphor-react';

import s from './Accordion.module.css';

export { AccordionItem, AccordionPanel } from '@reach/accordion';

export const Accordion = React.memo<AccordionProps>((props) => {
  return (
    <div className={s.root}>
      <ReachAccordion {...props} />
    </div>
  );
});

Accordion.displayName = 'Accordion';

export const AccordionButton = React.memo<AccordionButtonProps>(({ children, ...props }) => {
  return (
    <ReachAccordionButton {...props}>
      {children}
      <CaretDown className='w-6 h-6' />
    </ReachAccordionButton>
  );
});

AccordionButton.displayName = 'AccordionButton';
