import {
  Barbell,
  CookingPot,
  Icon,
  Image,
  MonitorPlay,
  Sparkle,
  SquaresFour,
  UserCircle,
  Users,
  Pencil,
} from 'phosphor-react';

type AdminSecondaryLink = {
  title: string;
  href: string;
};

type AdminLink = { title: string; href: string; icon: Icon; secondaryMenu?: AdminSecondaryLink[] | null };

export const adminMenu: AdminLink[] = [
  { title: 'Workouts', href: '/admin/workouts', icon: Barbell, secondaryMenu: null },
  { title: 'Instructors', href: '/admin/instructors', icon: UserCircle, secondaryMenu: null },
  { title: 'Phases', href: '/admin/phases', icon: SquaresFour, secondaryMenu: null },
  { title: 'Recipes', href: '/admin/recipes', icon: CookingPot, secondaryMenu: null },
  {
    title: 'Insights',
    href: '/admin/insights/cards',
    icon: Sparkle,
    secondaryMenu: [
      {
        title: 'Cards',
        href: '/admin/insights/cards',
      },
      {
        title: 'Thoughts',
        href: '/admin/insights/thoughts',
      },
      {
        title: 'Collections',
        href: '/admin/insights/collections',
      },
    ],
  },
  { title: 'Images', href: '/admin/images', icon: Image, secondaryMenu: null },
  { title: 'Videos', href: '/admin/videos', icon: MonitorPlay, secondaryMenu: null },
  { title: 'Users', href: '/admin/users', icon: Users, secondaryMenu: null },
  { title: 'Exercise Gear', href: '/admin/exerciseGear', icon: Pencil, secondaryMenu: null },
];
