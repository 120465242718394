import * as React from 'react';

import { SpeakerHigh, SpeakerLow, SpeakerX } from 'phosphor-react';

interface MuteButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  isMuted: boolean;
  isLowVolume: boolean;
}

export const MuteButton: React.FC<MuteButtonProps> = ({ isMuted, isLowVolume, ...props }) => {
  return (
    <button {...props} title={isMuted ? 'muted' : 'unmuted'} type='button'>
      {isMuted ? (
        <SpeakerX className='w-6 h-6' />
      ) : isLowVolume ? (
        <SpeakerLow className='w-6 h-6' />
      ) : (
        <SpeakerHigh className='w-6 h-6' />
      )}
    </button>
  );
};
