import * as React from 'react';

import { Listbox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { CaretDown, Check } from 'phosphor-react';
import { FieldErrors } from 'react-hook-form';

import { FormErrorMessage } from '@/components/common';

type Option = { text: string; value: string };

export interface SelectProps extends Omit<React.ComponentPropsWithRef<'select'>, 'onChange' | 'defaultValue'> {
  defaultValue: Option | null;
  errors?: FieldErrors;
  label?: string;
  onChange: (optionValue: Option['value']) => void;
  options: Option[];
}

export const Select = React.memo<SelectProps>(({ defaultValue, errors, onChange, label, options, ...props }) => {
  const [selectedOption, setSelectedOption] = React.useState<Option['value'] | null>(null);

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    onChange(value);
  };

  if (!selectedOption && defaultValue?.value) {
    handleOptionChange(defaultValue?.value);
  }

  return (
    <Listbox onChange={handleOptionChange} value={selectedOption}>
      {({ open }) => (
        <div>
          {label && <Listbox.Label className='font-lato font-bold block mb-2 text-sm'>{label}</Listbox.Label>}

          <div className={clsx('relative', label && 'mt-1')}>
            <Listbox.Button className='relative w-full cursor-default rounded-md border border-khaki-3 bg-white py-[14px] pl-4 pr-10 text-left focus:border-khaki-5 focus:outline-none'>
              <span className='block min-h-[28px] truncate'>
                {options.find((option) => option.value === selectedOption)?.text}
              </span>
              <span
                className={clsx(
                  'pointer-events-none absolute inset-y-0 right-5 flex items-center transition-transform',
                  open && 'rotate-180',
                )}
              >
                <CaretDown aria-hidden='true' size={16} />
              </span>
            </Listbox.Button>

            <Transition
              as={React.Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
              show={open}
            >
              <Listbox.Options className='absolute z-10 mt-2 max-h-64 w-full border border-khaki-2 space-y-2 overflow-auto rounded-md bg-white p-2 text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                {options.map((option) => (
                  <Listbox.Option
                    className={({ active }) =>
                      clsx('relative select-none py-2 pl-3 pr-10 rounded-md text-base', active && ' bg-purple-1/10')
                    }
                    key={option.value}
                    value={option.value}
                  >
                    {({ selected }) => (
                      <>
                        <span className='block truncate'>{option.text}</span>

                        {selected ? (
                          <span className='absolute inset-y-0 right-3 flex items-center'>
                            <Check aria-hidden='true' size={16} />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>

          <FormErrorMessage errors={errors} name={props.name as string} />
        </div>
      )}
    </Listbox>
  );
});

Select.displayName = 'Select';
