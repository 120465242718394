import * as React from 'react';

import { useUser } from '@clerk/nextjs';
import { useRouter } from 'next/router';
import { House } from 'phosphor-react';

import { Type } from '@/analytics/client';
import { Greeting } from '@/components/account';
import { DrawerLink } from '@/components/common';
import { Button, useUIDispatch } from '@/components/ui';
import { useAnalyticsDispatch } from '@/context/analytics';
import { useUrl } from '@/hooks/useUrl';
import { getUserMetadata } from '@/utils/auth/getUserMetadata';
import { trpc } from '@/utils/trpc';

type MenuLink = { title: string; href: string };

const MENU_LINKS: MenuLink[] = [
  {
    title: 'Method',
    href: '/method',
  },
  {
    title: 'Support',
    href: '/support',
  },
  {
    title: 'Shop',
    href: 'https://shop.28.co',
  },
  {
    title: '28 Collective',
    href: '/collective',
  },
  {
    title: 'About',
    href: '/about',
  },
  {
    title: 'Privacy',
    href: '/privacy',
  },
  {
    title: 'Terms of Use',
    href: '/terms',
  },
];

export const MobileMenu = React.memo(() => {
  const { replace } = useRouter();
  const { user } = useUser();
  const { userId } = getUserMetadata(user);

  const { data: dbUser } = trpc.users.byId.useQuery({ id: userId || '' }, { enabled: !!userId });

  const uiDispatch = useUIDispatch();

  const url = useUrl();

  const analyticsDispatch = useAnalyticsDispatch();

  return (
    <nav className='flex flex-col items-center gap-12'>
      <h2 className='sr-only'>Mobile Menu</h2>
      <DrawerLink className='text-khaki-1' href='/'>
        <House className='w-6 h-6' />
      </DrawerLink>
      {MENU_LINKS.map((link) => (
        <DrawerLink className='text-khaki-1 font-josefin' href={link.href} key={link.href}>
          {link.title}
        </DrawerLink>
      ))}
      {dbUser ? (
        <Greeting
          className='text-khaki-1 border-khaki-1'
          daysLeft={dbUser.data.daysLeft}
          onClickHandler={() => uiDispatch({ type: 'DRAWER_CLOSE' })}
          userCycleDuration={dbUser.data.cycleDuration}
          username={`${dbUser.data.firstName} ${dbUser.data.lastName}`}
        />
      ) : (
        <Button
          className='lg:inline-flex text-khaki-1 border-khaki-1'
          onClick={() => {
            uiDispatch({ type: 'DRAWER_CLOSE' });

            analyticsDispatch({
              type: 'CTA_CLICKED',
              payload: {
                url,
                destination: process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string,
                location: 'Mobile Menu',
                text: 'Join for Free',
                type: Type.Button,
              },
            });
            replace('/login');
          }}
          variant='secondary'
        >
          Join for Free
        </Button>
      )}
    </nav>
  );
});

MobileMenu.displayName = 'MobileMenu';
