import * as React from 'react';

import { useUser } from '@clerk/nextjs';
import NextImage from 'next/image';
import { useRouter } from 'next/router';

import { Type } from '@/analytics/client';
import { Greeting } from '@/components/account';
import { Link } from '@/components/common';
import { MobileMenuButton } from '@/components/layout';
import { Avatar, Button } from '@/components/ui';
import { useAnalyticsDispatch } from '@/context/analytics';
import { SITE_NAME, SITE_URL } from '@/lib/constants';
import { getUserMetadata } from '@/utils/auth/getUserMetadata';
import { resolveName } from '@/utils/resolveName';
import { getInitials } from '@/utils/string';
import { trpc } from '@/utils/trpc';

export const SiteHeader = React.memo(() => {
  const { user } = useUser();
  const { pathname, push, replace } = useRouter();

  const analyticsDispatch = useAnalyticsDispatch();

  const { userId, roles } = getUserMetadata(user);
  const isAdmin = roles?.includes('ADMIN');

  const { data: dbUser } = trpc.users.byId.useQuery({ id: userId || '' }, { enabled: !!userId });

  return (
    <>
      <header className='py-3 px-6 md:px-12 lg:py-4'>
        <div className='max-w-[1600px] m-auto grid grid-cols-[1fr_auto_1fr] items-center text-sm font-josefin text-slate'>
          <div className='flex items-center justify-start gap-12'>
            <MobileMenuButton />
            <Link className='hidden lg:block' data-testid='home-navigation-link' href='/'>
              <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
                <path
                  clipRule='evenodd'
                  d='M8.38371 0.840151C8.15802 0.664611 7.84198 0.664611 7.61629 0.840151L1.61629 5.50682C1.46405 5.62523 1.375 5.80729 1.375 6.00016V13.3335C1.375 13.8529 1.58132 14.351 1.94858 14.7182C2.31584 15.0855 2.81395 15.2918 3.33333 15.2918H6H10H12.6667C13.186 15.2918 13.6842 15.0855 14.0514 14.7182C14.4187 14.351 14.625 13.8529 14.625 13.3335V6.00016C14.625 5.80729 14.536 5.62523 14.3837 5.50682L8.38371 0.840151ZM10.625 14.0418H12.6667C12.8545 14.0418 13.0347 13.9672 13.1675 13.8344C13.3004 13.7015 13.375 13.5214 13.375 13.3335V6.30584L8 2.12528L2.625 6.30584V13.3335C2.625 13.5214 2.69963 13.7015 2.83247 13.8344C2.9653 13.9672 3.14547 14.0418 3.33333 14.0418H5.375V8.00016C5.375 7.65498 5.65482 7.37516 6 7.37516H10C10.3452 7.37516 10.625 7.65498 10.625 8.00016V14.0418ZM6.625 14.0418V8.62516H9.375V14.0418H6.625Z'
                  fill='#45525F'
                  fillRule='evenodd'
                />
              </svg>
            </Link>
            <Link className='hidden lg:block' data-testid='method-navigation-link' href='/method'>
              Method
            </Link>
            <Link className='hidden lg:block' data-testid='support-navigation-link' href='/support'>
              Support
            </Link>
            <Link className='hidden lg:block' data-testid='support-navigation-link' href='https://shop.28.co'>
              Shop
            </Link>
            {isAdmin && (
              <Link className='hidden lg:block' data-testid='admin-navigation-link' href='/admin/workouts'>
                Admin
              </Link>
            )}
            <Link className='hidden lg:block' data-testid='support-navigation-link' href='/collective'>
              28 Collective
            </Link>
          </div>
          <Link className='flex w-10 lg:w-16' href='/'>
            <h1 className='sr-only'>{SITE_NAME}</h1>
            <NextImage alt='28' height={62} src='/images/logo/gradient.svg' width={64} />
          </Link>
          <div className='flex items-center justify-end gap-12'>
            {dbUser ? (
              <>
                <Link className='lg:hidden' href='/account'>
                  <Avatar
                    letters={getInitials(resolveName(dbUser.data, user?.publicMetadata.username as string))}
                    variant='sm'
                  />
                </Link>
                <Greeting
                  className='hidden lg:flex'
                  daysLeft={dbUser.data.daysLeft}
                  userCycleDuration={dbUser.data.cycleDuration}
                  username={resolveName(dbUser.data, user?.publicMetadata.username as string)}
                />
              </>
            ) : (
              <>
                <button
                  data-testid='login-button-desktop'
                  onClick={() => {
                    replace('/login');
                  }}
                >
                  Login
                </button>
                <Button
                  className='hidden lg:inline-flex'
                  data-testid='start-free-trial-button-desktop'
                  onClick={() => {
                    push('/signup/questionnaire');
                    analyticsDispatch({
                      type: 'CTA_CLICKED',
                      payload: {
                        url: `${SITE_URL}${pathname}`,
                        destination: process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string,
                        location: 'Desktop: Header',
                        text: 'Join for Free',
                        type: Type.Button,
                      },
                    });
                  }}
                  variant='primary'
                >
                  Join for Free
                </Button>
              </>
            )}
          </div>
        </div>
      </header>
    </>
  );
});

SiteHeader.displayName = 'SiteHeader';
