import * as React from 'react';

import clsx from 'clsx';
import { ClosedCaptioning } from 'phosphor-react';

import { Radio } from '@/components/ui';

export const SubtitlesButton: React.FC<
  React.ComponentPropsWithoutRef<'select'> & {
    onChangeHandler: React.ChangeEventHandler<HTMLInputElement>;
    subtitleTracks:
      | {
          id: number;
          name: string;
          lang?: string;
        }[]
      | null;
  }
> = ({ subtitleTracks, onChangeHandler }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  if (!subtitleTracks) return null;

  return (
    <div className='relative'>
      <button className='hover:scale-110 transition-transform' onClick={() => setIsVisible(!isVisible)}>
        <ClosedCaptioning className='w-6 h-6' />{' '}
      </button>
      <div
        className={clsx(
          'min-w-[200px] right-0 p-6 flex flex-col gap-3 bg-[#333D47] z-50 text-khaki-1 absolute bottom-10 rounded-lg',
          {
            hidden: !isVisible,
          },
        )}
      >
        <div className='font-josefin font-bold'>Subtitles</div>
        <Radio defaultChecked label='Off' name='subtitle' onChange={onChangeHandler} value='-1' />
        {subtitleTracks.map((track) => (
          <Radio key={track.id} label={track.name} name='subtitle' onChange={onChangeHandler} value={track.id} />
        ))}
      </div>
    </div>
  );
};
