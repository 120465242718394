import * as React from 'react';

import clsx from 'clsx';
import { User } from 'phosphor-react';

type AvatarVariant = 'sm' | 'lg';

interface AvatarProps extends React.ComponentPropsWithoutRef<'div'> {
  variant: AvatarVariant;
  letters?: string;
}

function stringToHsl(str: string, s: number, l: number) {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;

  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

export const Avatar = React.memo<AvatarProps>(({ letters = '', variant, className }) => {
  return (
    <div
      className={clsx(
        'flex shrink-0 items-center self-center justify-center rounded-full font-bold text-center text-white',
        {
          'w-10 h-10 text-xs': variant === 'sm',
          'w-16 h-16 lg:w-16 lg:h-16 lg:text-lg': variant === 'lg',
        },
        className,
      )}
      role='img'
      style={{ backgroundColor: stringToHsl(letters.toUpperCase(), 40, 60) }}
    >
      {letters ? <span className='translate-y-px'>{letters}</span> : <User className='w-5 h-5' />}
    </div>
  );
});

Avatar.displayName = 'Avatar';
