import * as React from 'react';

import { useUser } from '@clerk/nextjs';
import { useRouter } from 'next/router';
import { Play, LockSimple } from 'phosphor-react';

import { Type } from '@/analytics/client';
import { Button, useUIDispatch } from '@/components/ui';
import { useAnalyticsDispatch } from '@/context/analytics';
import { useUrl } from '@/hooks/useUrl';
import { findPlayback } from '@/utils/mux';
import { trpc } from '@/utils/trpc';

import { RouterOutput } from '../../../trpc/router';

export interface WorkoutPlayButtonProps {
  workout: RouterOutput['workouts']['byId']['data'];
}

export const WorkoutPlayButton = React.memo<WorkoutPlayButtonProps>(({ workout }) => {
  const { user, isLoaded } = useUser();
  const { replace } = useRouter();
  const url = useUrl();

  const { data: playbacks } = trpc.videos.playbacks.useQuery(
    { workoutId: workout.id, videoId: workout.videoId ?? '' },
    {
      enabled: !!user,
      select: (data) => data?.playbacks ?? [],
    },
  );
  const { data: workoutbyUser, isInitialLoading: isWorkoutLoading } = trpc.workouts.byId.useQuery(
    { id: workout.id },
    { enabled: !!user && !!workout.id },
  );

  const isLoading = isWorkoutLoading || !isLoaded;

  const playback = React.useMemo(() => findPlayback(playbacks), [playbacks]);

  const uiDispatch = useUIDispatch();
  const analyticsDispatch = useAnalyticsDispatch();

  const handlePlay = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();

      if (playback) {
        uiDispatch({
          type: 'WORKOUT_PLAYER_OPEN',
          playback: playback,
          workout: workout,
        });
      }
    },
    [playback, uiDispatch, workout],
  );

  const handleClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();

      analyticsDispatch({
        type: 'CTA_CLICKED',
        payload: {
          url,
          destination: process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string,
          location: 'Workout: Play Button',
          text: 'Sign Up Now',
          type: Type.Button,
        },
      });

      replace('/login');
    },
    [analyticsDispatch, url],
  );

  if (!isLoading && !user) {
    return (
      <Button data-testid='unauthenticated-sign-up-now-button' onClick={handleClick} variant='secondary'>
        Join for Free
      </Button>
    );
  }

  return (
    <Button
      data-testid='workouts-play-now-button'
      disabled={!playback?.muxPlaybackId || workoutbyUser?.data?.isLocked}
      onClick={handlePlay}
      size='lg'
      type='button'
      variant='primary'
    >
      {!playback?.muxPlaybackId || workoutbyUser?.data?.isLocked ? (
        <LockSimple className='w-4 h-4' weight='fill' />
      ) : (
        <Play className='w-4 h-4' weight='fill' />
      )}
      Play Now
    </Button>
  );
});

WorkoutPlayButton.displayName = 'WorkoutPlayButton';
