/* eslint-disable jsx-a11y/accessible-emoji */
import * as React from 'react';

import { User } from '@prisma/client';
import clsx from 'clsx';
import { Check, Heart, HeartBreak, Trash, X } from 'phosphor-react';

import { SubscriptionStatusIndicator } from '@/components/common';
import { Divider, useUIDispatch } from '@/components/ui';
import { useUserDelete } from '@/request/client';

import { UserAdminInfoModal } from '../UserAdminInfoModal';
import { UserPromotionForm } from '../UserGrantPromotionForm';
import { UserRevokePromotionForm } from '../UserRevokePromotionForm';

export type UserAdminTableRowProps = { user?: User };

export const UserAdminTableRow = React.memo<UserAdminTableRowProps>(({ user }) => {
  const { deleteUser, isLoading } = useUserDelete(user?.id);

  const uiDispatch = useUIDispatch();

  if (user) {
    const status = user.isSubscriber ? 'active' : '';

    return (
      <tr className='hover:bg-khaki-2' key={user.id}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
        <td
          className='hover:cursor-pointer'
          onClick={() => uiDispatch({ type: 'MODAL_OPEN', content: <UserAdminInfoModal user={user} /> })}
        >
          {user.email}
        </td>
        <td>{user.isAdmin ? <Check className='w-4 h-4' /> : <X className='w-4 h-4' />}</td>
        <td>
          <SubscriptionStatusIndicator status={status} />
        </td>
        <td>
          <div className='flex flex-row items-center'>
            {!user.isSubscriber && (
              <button
                className={clsx('text-sm flex gap-x-2 items-center', user.isAdmin && 'line-through cursor-not-allowed')}
                disabled={user.isAdmin}
                onClick={() => uiDispatch({ type: 'MODAL_OPEN', content: <UserPromotionForm user={user} /> })}
                title={user.isAdmin ? 'Admins cannot be promoted' : undefined}
                type='button'
              >
                <Heart className='mt-0.5' />
                Grant Promo
              </button>
            )}
            {user.isSubscriber && (
              <button
                className='text-sm  flex gap-x-2 items-center'
                onClick={() => uiDispatch({ type: 'MODAL_OPEN', content: <UserRevokePromotionForm user={user} /> })}
                type='button'
              >
                <HeartBreak className='mt-0.5' />
                Revoke Promo
              </button>
            )}
            <Divider className='mx-4 h-4 border-khaki-4' direction='vertical' variant='simple' />
            <button
              className='text-sm text-red flex gap-x-2 items-center'
              disabled={isLoading}
              onClick={deleteUser}
              type='button'
            >
              <Trash className='mt-0.5' />
              Delete
            </button>
          </div>
        </td>
      </tr>
    );
  }

  return (
    <tr className='animate-pulse'>
      <td>
        <div className='flex items-center gap-6'>
          <div className='bg-khaki-2-3 h-10 w-10 rounded-full' />
          <div className='h-2 bg-khaki-2-3 grow max-w-xs rounded' />
        </div>
      </td>
      <td>
        <div className='h-2 w-16 bg-khaki-2-3 rounded' />
      </td>
      <td>
        <div className='h-2 w-16 bg-khaki-2-3 rounded' />
      </td>
      <td>
        <div className='h-2 w-16 bg-khaki-2-3 rounded' />
      </td>
      <td>
        <div className='h-2 w-16 bg-khaki-2-3 rounded' />
      </td>
    </tr>
  );
});

UserAdminTableRow.displayName = 'UserAdminTableRow';
